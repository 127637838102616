<template>
  <div class="template">
    <nav-logged v-if="$vuetify.breakpoint.mdAndUp"></nav-logged>
    <div class="dashboard page grey lighten-4" :class="{'mobile': $vuetify.breakpoint.smAndDown}">
      <v-container fill-height  class="align-start">


        <v-row class=" mx-0 rounded-lg" no-gutters style="overflow:hidden;" v-if="!$isEmpty(localUser)"
          :class="{'fill-height': $vuetify.breakpoint.mdAndUp}">

          <v-col cols="12" md="3" class="profile-details  pa-md-2">
            <v-row class="header " no-gutters align="center" v-if="$vuetify.breakpoint.mdAndUp">
              <v-col cols="auto" md="6">
                <div class="profile-photo pt-3 pl-4 pa-md-2">
                  <template v-if="!$isEmpty(localUser.photoURL)">
                    <img :src="localUser.photoURL.cropped" alt="" class="profile-img elevation-6" :class="{'rounded' : $vuetify.breakpoint.mdAndUp, 'rounded-circle' : $vuetify.breakpoint.smAndDown}">
                  </template>
                  <template v-else>
                    <!-- <img :src="localUser.basicAvatar" alt="" class="profile-img mb-2 rounded elevation-6"> -->
                    <v-avatar size="60" color="white" class="headline black--text" v-if="!$isUndefined(localUser.firstName)">
                      {{localUser.firstName.charAt(0)}}
                    </v-avatar>
                  </template>
                </div>
              </v-col>
              <v-col cols="12" class="ml-2 justify-items-center ">
                <h3>{{localUser.firstName}} {{localUser.lastName}}</h3>
                <h5 v-if="$vuetify.breakpoint.smAndDown"><a :href="`/@${localUser.userName}`">@{{localUser.userName}}</a></h5>
              </v-col>
            </v-row>

              <template v-if="$vuetify.breakpoint.mdAndUp">
                <v-list flat color="transparent" >
                  <v-list-item-group

                    color="primary"
                    >
                    <v-list-item v-for="(item, i) in showLinks" :key="i" :to="item.path" exact>
                      <v-list-item-icon size="14">
                        <v-icon v-text="item.icon"></v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title v-text="item.text"></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </template>
          </v-col>

          <v-col cols="12" md="9">
              <router-view></router-view>
          </v-col>
        </v-row>

      <!-- <v-col cols="12">
        <v-btn color="success" @click="addCalendar()">Add Calendar</v-btn>
        <iframe style="width:100%; height:70vh" :src="calAuthUrl" v-if="calAuthUrl" class="d-block"></iframe>
      </v-col> -->



      <v-snackbar
        v-model="snackbar.show"
        :color="snackbar.color"
        dark>
          {{ snackbar.message }}
          <v-btn
            text
            color="white"
            @click="snackbar.show = false">
            Close
          </v-btn>
      </v-snackbar>


      </v-container>
    </div>
    <nav-logged-mobile v-if="$vuetify.breakpoint.smAndDown"></nav-logged-mobile>
  </div>
</template>

<script>
// const mainNav = () => import("@/components/shared/nav.vue");
// const mobileNav = () => import("@/components/shared/mobile-nav.vue");
const navLogged = () => import("@/components/shared/nav-logged.vue");
const navLoggedMobile = () => import("@/components/shared/nav-logged-mobile.vue");

import { cloud, storage, db } from '@/firebase';
import { mapGetters } from 'vuex'

export default {
  name: "Dashboard",
  components:{
    navLogged, navLoggedMobile
  },
  computed:{
     getPercentages(arr){
        return true;
    },
    showLinks(){
      return this.links.filter(item => this.$isUndefined(item.showMobile))
    },
    userId: function (){ return this.$store.state.userId},
    user: function (){ return this.$store.state.user},
    links: function(){ return this.$store.state.mobile_menu}
  },
  data(){
    return{
      value:0,
      calAuthUrl:false,
      isLoaded: false,
      showNewAccount: false,
      profileChecker: "",
      localUser:[],
      showAvatar:false,
      avatarType: false,
      preferAvatar: false,
      displayName: "",
      selectedTab: 0,
      snackbar:{
        show:false,
      },
    //   links:[
    //   // {text:'Dashboard', path:`/my`, icon: 'mdi-home'},
    //   {text:'Dashboard', path:`/my`, icon: 'mdi-chart-timeline-variant-shimmer'},
    //   // {text:'Dashboard', path:`/my`, icon: 'mdi-finance'},
    //   {text:'Meetings', path:`/my/calendar`, icon: 'mdi-calendar-blank-outline'},
    //   {text:'Proposals', path:`/my/proposals`, icon: 'mdi-handshake-outline'},
    //   // {text:'Billing', path:`/my/billing`, icon: 'mdi-currency-usd'},
    //   {text:'Settings', path:`/my/settings`, icon: 'mdi-account-outline'},
    // ],
      avatar: "https://avataaars.io/?accessoriesType=Prescription02&avatarStyle=Transparent&clotheColor=Gray02&clotheType=Hoodie&eyeType=Happy&eyebrowType=UpDownNatural&facialHairColor=BlondeGolden&facialHairType=MoustacheMagnum&graphicType=Resist&hairColor=BrownDark&hatColor=Gray01&mouthType=Grimace&skinColor=Pale&topType=ShortHairShortWaved",
    }
  },
  watch:{
    '$route': 'getUser'
  },
  methods:{
    getUser(){
      let vThis = this;
      this.$store.dispatch('getCurrentUser').then(results =>{
        process.env.NODE_ENV === "development" ? console.log( 'results from get user', results ) : null;
        this.localUser = results.data;

      }).catch(error =>{
          vThis.loadUser();
      })
    },
    loadUser(){
      let vThis = this;
      db.collection('users').where('userName', '==', this.$route.params.userName).get().then((result)=>{
        if (result.empty) {
          console.log('No matching documents.');
          return;
        }
        result.forEach(doc => {
          console.log(doc.id, '=>', doc.data());
          vThis.localUser = doc.data();
        });
      })
    }
  },
  mounted(){
    this.getUser();
  }
};
</script>


<style lang="scss">
.template{
  position: relative;
}
.dashboard.page{
  background-color: #f2f2f2;
  position: relative;

  .profile-photo{
    .profile-img{
      max-width: 100%;
      border-radius: 6px;
      // border: 3px solid white;
    }
    h2{
      line-height: 95%;
    }
    .company-links{
      text-decoration: none;
    }
  }
  .header{
    position: relative;
    .name{
      line-height: 30px;
    }
    .edit{
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
  .v-card{
    .v-card__text{
      position: relative;
      .editBtn{
        position: absolute;
        right: 30%
      }
    }
  }
  &.mobile{
    padding-top:0;
    padding-left: 0;
    .container{
      padding: 0;
      .row {
        margin: 0;
        > div{
          padding: 0;
        }
      }
      .v-card{
        padding-bottom: 12px;
      }
    }
    .profile-details{
      .profile-img{
        max-width:48px;
        // max-width: 80vw;
        // border-radius: 0;
        // border: none;
      }
    }
    .meet-me.v-btn{
      // margin-top: -20px;
      border-radius: 6px;
      // text-shadow: 1px 1px 5px rgba(0,0,0,.60);
      background-color: rgba(0,0,0,.35)!important;
      border: 3px solid white!important;
    }
  }
}

</style>
